export const iFramePermissions ={
    'EntityList' :{
        // 'ADD':false,
        'VIEW':true,
        // 'EDIT':false,
        'DELETE':false,
        'EXPORT':false,
    },
    'ViewEntityData' :{
        'ADD':true,
        'VIEW':true,
        'EDIT':true,
        'DELETE':true,
        'SEND NOTIFICATION':true,
        'EXPORT':true,
        'IMPORT':false,
        'FILTERS':false,
        'SETTINGS':false,
        'LAYOUT OPTIONS':false,
        'BULK UPDATE' : false,
        'TEMPLATES':{

        }
    },
    'EntityGalleryView' :{
        'ADD':true,
        'VIEW' :true,
        'FILTERS' : false,
        'SETTINGS':false,
        'LAYOUT OPTIONS':false,
        'TEMPLATES':{

        }
    },
    'EntityDetailedView' :{
        'EDIT':true,
        'LAYOUT OPTIONS':false,
        'LAYOUT' : false,


    },
    'FormBuilderList' :{
        'VIEW':true,
        'DELETE':true,
        'GET LINK': true,    
    },
    'FormBuilderUserDate' :{
        'ADD' : true,
        'VIEW':true,
        'EDIT':true,
        'DELETE':true,
        'DETAILED VIEW' : true,
        'GET LINK': true, 
    },
    'FormBuildersTemplateDataList' :{
        'Export' : true,
    }
}